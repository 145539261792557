//上传至阿里云服务器
const OSS = require("ali-oss");

const client  = new OSS({
    accessKeyId: 'LTAIcZppIodBTNw6',
    accessKeySecret: 'B8d5IyFuI0Qvh6ydGNZ1B425B9CyZA',
    region: 'oss-cn-shenzhen',
    endpoint: 'oss-cn-shenzhen.aliyuncs.com',
    bucket: 'hires',
    secure: true,
})

let nowDate = new Date();
let year = nowDate.getFullYear();
let month = nowDate.getMonth() + 1;
let date = nowDate.getDate();
let pkg = "upvideo/" + year + (month < 10 ? "0" + month : month) + (date < 10 ? "0" + date : date) + "/";

export default {
    uploadFile(file,callBack,errBack) {
        if (Object.prototype.toString.call(file) === "[object Array]") {
            file.forEach((item,index) => {
                item.index = index;
                this.putFile(item,callBack,errBack);
            })
        }else if(Object.prototype.toString.call(file) === '[object Object]'){
            this.putFile(file,callBack,errBack);
        }else{
            //非对象非数组
        }
    },
    putFile(file,callBack,errBack){
        let fileName = pkg + file.file.name;
        client.put(fileName,file.file).then(res => {
            if(res.res.status === 200){
                //let url = client.signatureUrl(res.name, {expires: 3600});
                res.index = file.index;
                if(typeof callBack === "function") callBack(res);
            }
        }).catch(err => {
            if(typeof errBack === "function") errBack(err);
        })
    }
};
